import { CssVarsThemeOptions } from '@mui/material';
import { deepmerge } from '@mui/utils';
import AESLogo from '@/app/[tenantAlias]/advantageelectricsupply/_assets/aes-logo.svg';
import AESMonochromaticLogo from '@/app/[tenantAlias]/advantageelectricsupply/_assets/aes-monochromatic-logo.svg';
import {
  createMetadata,
  createOrganizationJSONLD,
} from '@/global/config/ConfigHelper';
import { SPSConfig } from '@/global/types/SPSConfig';
import { globalComponentsMap } from '@/globalComponents/global-components-map';
import { TenantAlias } from '@/shared/tenants-alias-map';

const TENANT_ALIAS: TenantAlias = 'advantageelectricsupply';

/**
 * Copy and paste the generated theme config from the Figma design using `Material UI Sync plugin`.
 * @see https://mui.com/material-ui/design-resources/material-ui-sync/
 */
const themeFromFigma: CssVarsThemeOptions = {
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: '#2270E0',
          dark: '#003F99',
          light: '#74ADFF',
          '50p': '#96B7EA',
          '30p': '#C1D5F3',
          '12p': '#C1D5F3',
          '8p': '#EFF4FC',
          '4p': '#F6F9FD',
          contrastText: '#FFF',
        },
        secondary: {
          main: '#802392',
          dark: '#50005D',
          light: '#AE00CF',
          '50p': '#B893C4',
          '30p': '#D4BFDD',
          '12p': '#EDE4F1',
          '8p': '#F4EEF6',
          '4p': '#F9F6FB',
          contrastText: '#FFF',
        },
      },
    },
  },
};

/**
 * Any local overrides can be applied here:
 */
const localOverrides: CssVarsThemeOptions = {};

const HUMAN_READABLE_TENANT_NAME = 'Advantage Electric Supply';
const SITE_NAME = `${HUMAN_READABLE_TENANT_NAME}`;
const HOST = `https://advantageelectricsupply.com`;
const SITE_DESCRIPTION = `We pride ourselves on our service, and promise to deliver the highest quality goods at the best prices. As one of the leading suppliers of electrical and electronic components we are committed to excellence by delivering our service with speed, courtesy, and reliability!`;
const EMAIL = 'sales@advantageelectricsupply.com';
const PHONE = '(510) 324-9070';
const STREET = '31857 Hayman St.';
const CITY = 'Hayward';
const STATE = 'California';
const ZIP = '94544';
const COUNTRY = 'USA';

export const spsConfig: SPSConfig = {
  tenantAlias: TENANT_ALIAS,
  humanReadableTenantName: HUMAN_READABLE_TENANT_NAME,
  siteName: SITE_NAME,
  host: HOST,
  siteDescription: SITE_DESCRIPTION,
  email: EMAIL,
  phone: PHONE,
  street: STREET,
  city: CITY,
  state: STATE,
  zip: ZIP,
  country: COUNTRY,
  tenantTheme: deepmerge(themeFromFigma, localOverrides),
  metadata: createMetadata(
    TENANT_ALIAS,
    SITE_NAME,
    HOST,
    SITE_DESCRIPTION,
    HUMAN_READABLE_TENANT_NAME,
  ),
  organizationJSONLD: createOrganizationJSONLD(
    TENANT_ALIAS,
    SITE_NAME,
    HOST,
    SITE_DESCRIPTION,
    EMAIL,
    PHONE,
    {
      streetAddress: STREET,
      addressLocality: CITY,
      addressRegion: STATE,
      postalCode: ZIP,
      addressCountry: COUNTRY,
    },
  ),
  componentDirectory: globalComponentsMap,
  headerLogo: AESLogo,
  headerLogoWidth: 165,
  headerLogoHeight: 35,
  headerLinks: [
    {
      href: '/',
      label: 'Home',
    },
    {
      href: '/about',
      label: 'About us',
    },
    {
      href: '/services',
      label: 'Services',
    },
    {
      href: '/contact',
      label: 'Contact',
    },
  ],
  footerLogo: AESMonochromaticLogo,
  footerLogoWidth: 426,
  footerLogoHeight: 91,
  footerText: SITE_DESCRIPTION,
  footerLinks: [
    { label: 'Home', href: '/' },
    { label: 'About Us', href: '/about' },
    { label: 'Contact Us', href: '/contact' },
    { label: 'Value Added Services', href: '/services' },
    {
      label: 'Terms of Service',
      href: 'https://app.termly.io/policy-viewer/policy.html?policyUUID=ecfb22dd-8e59-4f85-8fb0-4ebf109495dc',
      shouldOpenInNewTab: true,
    },
    {
      label: 'Return Policy',
      href: 'https://app.termly.io/policy-viewer/policy.html?policyUUID=7e3ad5d4-1370-4642-84f6-fe48bf2662a4',
      shouldOpenInNewTab: true,
    },
    {
      label: 'Privacy Policy',
      href: 'https://app.termly.io/policy-viewer/policy.html?policyUUID=ad218bac-5594-4102-9147-f86aa8405ff5',
      shouldOpenInNewTab: true,
    },
    {
      label: 'Cookie Policy',
      href: 'https://app.termly.io/policy-viewer/policy.html?policyUUID=33101b9e-f3ef-48f7-a215-deb22566362b',
      shouldOpenInNewTab: true,
    },
    {
      label: 'Disclaimer',
      href: 'https://app.termly.io/policy-viewer/policy.html?policyUUID=c2682ea1-71b6-4f59-9450-5e79543fd905',
      shouldOpenInNewTab: true,
    },
  ],
};
