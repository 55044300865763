import { PropsWithChildren } from 'react';
import { tenantStoreDi } from '@/global/tenantStore/tenantStoreDi';
import { SPSConfig } from '@/global/types/SPSConfig';
export function BaseTemplate({
  children,
  spsConfig
}: PropsWithChildren<{
  spsConfig: SPSConfig;
}>) {
  tenantStoreDi.set(spsConfig.componentDirectory);
  return <>{children}</>;
}