import { Metadata } from 'next/dist/lib/metadata/types/metadata-interface';
import { WithContext, Organization } from 'schema-dts';
import { TenantAlias } from '@/shared/tenants-alias-map';

export const createMetadata = (
  tenantAlias: TenantAlias,
  siteName: string,
  host: string,
  siteDescription: string,
  humanReadableTenantName: string,
): Metadata => {
  return {
    applicationName: siteName,
    metadataBase: new URL(host),
    manifest: '/manifest.json',
    title: {
      default: humanReadableTenantName,
      template: `%s | ${humanReadableTenantName}`,
    },
    description: siteDescription,
    robots: {
      follow: false,
      index: false,
    },
    icons: {
      icon: {
        url: `/favicons/${tenantAlias}/favicon.ico`,
        sizes: '32x32',
      },
      apple: `/favicons/${tenantAlias}/apple-touch-icon.png`,
    },
    openGraph: {
      description: siteDescription,
      url: host,
      siteName: siteName,
      images: [
        {
          url: `${host}/og`,
          width: 1200,
          height: 630,
          alt: siteName,
        },
      ],
      locale: 'en_US',
      type: 'website',
    },
  };
};

export const createOrganizationJSONLD = (
  tenantAlias: TenantAlias,
  siteName: string,
  host: string,
  siteDescription: string,
  contactEmail: string,
  phone: string,
  address: Address,
): WithContext<Organization> => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Corporation',
    name: siteName,
    description: siteDescription,
    url: new URL(host).origin,
    // Any size (at least 160x90 pixels and at most 1920x1080 pixels)
    logo: `${host}/favicons/${tenantAlias}/source_icon.svg`,
    // Any size (at least 160x90 pixels and at most 1920x1080 pixels)
    image: `${host}/og`,
    foundingDate: '1972',
    email: contactEmail,
    telephone: phone,
    address: {
      '@type': 'PostalAddress',
      streetAddress: address.streetAddress,
      addressLocality: address.addressLocality,
      addressRegion: address.addressRegion,
      postalCode: address.postalCode,
      addressCountry: address.addressCountry,
    },
    contactPoint: {
      '@type': 'ContactPoint',
      contactType: 'sales',
      telephone: phone,
      email: contactEmail,
    },
    sameAs: [`https://${tenantAlias}.salespilotsites.com`],
  };
};

type Address = {
  streetAddress: string;
  addressLocality: string;
  addressRegion: string;
  postalCode: string;
  addressCountry: string;
};
